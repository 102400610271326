import React, { useEffect, useState } from "react";
import { Card, Result, Typography, Layout } from "antd";
import { useParams } from "react-router-dom";
import innercorlogo from "../../assets/innercor-logo.png";
import "./index.css";
import axios from "axios";

const { Footer } = Layout;

const LinkPago = () => {
  const [status, setStatus] = useState({ type: "verifying" });
  const { codLinkPago } = useParams();

  useEffect(() => {
    const checkLinkPago = async () => {
      try {
        const apiURL = `${process.env.REACT_APP_API_URL}/api/LinkPago/GetLinkPago?codLinkPago=${codLinkPago}`;
        const response = await axios.get(apiURL);

        if (response.status === 404) {
          setStatus({ type: "badParam", ...response.data });
        } else if (response.status === 400) {
          setStatus({ type: "error", ...response.data });
        } else if (response.status === 200) {
          setStatus({ type: "success", ...response.data });
          setTimeout(() => {
            window.location.href = response.data;
          }, 1000);
        }
      } catch (error) {
        setStatus({ type: "error", ...error?.response?.data });
      }
    };

    setTimeout(checkLinkPago, 1000); // Simular retardo de 1 segundo
  }, [codLinkPago]);

  return (
    <Layout className="layout">
      <div className="header">
        <img src={innercorlogo} alt="Logo OnePOS" className="logo" />
        <h3>OnePOS</h3>
      </div>
      <div className="message-container">
        <Card className="message-card">
          {status?.type === "verifying" && (
            <Result title="Verificando ..." style={{ fontSize: "5px" }} />
          )}

          {status?.type === "error" && (
            <Result status="error" title={status?.mensaje} />
          )}
          {status?.type === "badParam" && (
            <Result status="warning" title={status?.mensaje} />
          )}
          {status?.type === "success" && (
            <Result
              status="success"
              title="Validación exitosa. Redirigiendo ..."
            />
          )}
        </Card>
      </div>
      <Footer className="footer">
        <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
          © {new Date().getFullYear()} Innercor Soluciones ERP
        </Typography.Text>
      </Footer>
    </Layout>
  );
};

export default LinkPago;
